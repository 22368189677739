import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import Entry from './Entry'
import axios from 'axios'
import { Space, Button } from 'antd'
import {
  DisconnectOutlined,
  RedoOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useLocation, createSearchParams } from 'react-router-dom'

const apiUrl = process.env.REACT_APP_API_URL

type Tour = {
  id?: number
  title?: string
  meeting_number?: string
  meeting_pwd?: string
  created_at?: string
  updated_at?: string
  live?: any
}

const Top: React.FC = () => {
  const location: any = useLocation()
  const query = new URLSearchParams(location.search)
  const data = {
    name: query.get('name'),
    meeting_number: query.get('mn'),
    meeting_pwd: query.get('pwd'),
  }

  const [tour, setTour] = useState<Tour>()
  const [sites, setSites] = useState<Array<any>>([])
  const [zoomUrl, setZoomUrl] = useState<string | undefined>()
  const [showZoom, setShowZoom] = useState<boolean>(false)
  const [liveSite, setLiveSite] = useState<any>({}) // 表示中のサイト情報
  const [holdSite, setHoldSite] = useState<any>({}) // 待機中のサイト情報
  const [siteId, setSiteId] = useState<number | undefined>()
  const [holding, setHolding] = useState<boolean>(false)
  const [showSite, setShowSite] = useState<boolean>(false)
  const [holdShow, setHoldShow] = useState<boolean>(false)
  const [showList, setShowList] = useState<boolean>(false)
  const [openList, setOpenList] = useState<boolean>(true)
  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false)
  const [showChangeDialog, setShowChangeDialog] = useState<boolean>(false)

  const liveSiteRef = useRef(liveSite)
  const holdingRef = useRef(holding)
  const showCloseDialogRef = useRef(showCloseDialog)
  const showChangeDialogRef = useRef(showChangeDialog)
  liveSiteRef.current = liveSite
  holdingRef.current = holding
  showCloseDialogRef.current = showCloseDialog
  showChangeDialogRef.current = showChangeDialog

  // ミーティングID、パスワードがない場合はページを非表示
  if (!(data.meeting_number && data.meeting_pwd)) {
    return <>not found</>
  }

  // 名前がない場合はログイン画面を表示
  if (!data.name) {
    return <Entry />
  }

  // 初回読み込み処理
  useEffect(() => {
    console.log(data)
    generateSignature()
    getTour()
  }, [])

  const generateSignature = async () => {
    try {
      const res = await axios.post(`${apiUrl}/generate_signature`, {
        meeting_number: data.meeting_number,
      })
      console.log(res.data)
      const param: URLSearchParams = createSearchParams({
        name: data.name ? data.name : '',
        mn: data.meeting_number ? data.meeting_number : '',
        email: '',
        pwd: data.meeting_pwd ? data.meeting_pwd : '',
        role: '0',
        lang: 'jp-JP',
        signature: res.data.signature,
        china: '0',
        apiKey: res.data.apiKey,
      })
      setZoomUrl(`/zoom/meeting.html?${param}`)
      setTimeout(() => {
        setShowZoom(true)
      }, 1200)
    } catch (error) {
      console.log(error)
    }
  }

  // 初回ツアー情報取得
  const getTour = async () => {
    try {
      const res = await axios.get(`${apiUrl}/tour`, {
        params: {
          mn: data.meeting_number,
          pwd: data.meeting_pwd,
        },
      })
      console.log('getTour', res.data)
      setTour(res.data)
      setSites(res.data.sites)

      if (res.data.live) {
        setLiveSite(res.data.live.site)
        setSiteId(res.data.live.id)
        setShowSite(res.data.display ? true : false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 商品一覧表示フラグ
  const checkShowList = async () => {
    if (!tour) {
      return
    }
    try {
      const res = await axios.get(`${apiUrl}/show_list/${tour.id}`)
      console.log(res.data)
      setShowList(res.data.show_list)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkShowList()
    const push = setInterval(() => {
      checkShowList()
    }, 5000)
    return () => clearInterval(push)
  }, [tour])

  // サイト未登録時：常時ツアー情報取得
  useEffect(() => {
    if (siteId) {
      return
    }
    const pushTour = setInterval(() => {
      fetchTour()
    }, 5000)
    return () => clearInterval(pushTour)
  }, [tour, siteId])

  const fetchTour = async () => {
    if (!tour) {
      return
    }
    try {
      const res = await axios.get(`${apiUrl}/tour/${tour.id}`)
      console.log(res.data)
      if (res.data.live) {
        setSiteId(res.data.live.id)
        getLiveSite(res.data.live.id)
      } else {
        setSiteId(undefined)
        checkChangeSite(undefined, false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // サイト登録時：常時サイト情報取得
  useEffect(() => {
    if (!siteId) {
      return
    }
    getLiveSite(siteId)
    const fetchSite = setInterval(() => {
      getLiveSite(siteId)
    }, 5000)
    return () => clearInterval(fetchSite)
  }, [siteId, showSite])

  // liveテーブルのIDからサイト情報を取得
  const getLiveSite = async (id: number | undefined) => {
    if (!tour || !id) {
      return
    }
    try {
      const res = await axios.get(`${apiUrl}/lives/${id}`)
      const resDisplay: boolean = res.data.display ? true : false
      setHoldSite(res.data.site || undefined)
      setHoldShow(resDisplay)
      checkChangeSite(res.data.site || undefined, resDisplay)
    } catch (error) {
      fetchTour()
      console.log(error)
    }
  }

  // サイトを変更するかどうかの判断
  const checkChangeSite = (site: any, display: boolean): any => {
    if (
      showCloseDialogRef.current ||
      showChangeDialogRef.current ||
      holdingRef.current
    ) {
      return
    }
    console.log(site.id, liveSiteRef.current.id)
    if (!showSite && display) {
      // false -> true
      setLiveSite(site)
      setShowSite(true)
    }
    if (showSite && !display) {
      // true -> false サイトを閉じる
      setShowCloseDialog(true)
    }
    if (showSite && display && site.id !== liveSiteRef.current.id) {
      // true -> true サイトが変わった場合、サイトを変更する
      setShowChangeDialog(true)
    }
    if (!showSite && !display) {
      // false -> false 非表示のまま何もしない
    }
  }

  const container = css`
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #fdfdfd;
    @media (orientation: portrait) {
      flex-direction: column;
      overflow: hidden;
    }
  `
  const zoomWrap = css`
    flex: 1;
    min-height: 100vh;
    @media (orientation: portrait) {
      width: 100%;
      height: 56vh;
      min-height: auto;
    }
  `
  const siteWrap = css`
    width: 320px;
    min-height: 100vh;
    padding: 20px 40px;
    background: -moz-linear-gradient(top left, #fff, #eee);
    background: -webkit-linear-gradient(top left, #fff, #eee);
    background: linear-gradient(to bottom right, #fff, #eee);
    @media (orientation: portrait) {
      width: 100%;
      height: 44vh;
      min-height: auto;
      overflow-y: auto;
      padding: 10px;
    }
  `
  const iframe = css`
    width: 100%;
    height: 100%;
    @media (orientation: portrait) {
      transform: scale(50%) translate(-50%, -50%);
      width: 200%;
      height: 200%;
    }
  `
  const hideIframe = css`
    width: 0;
  `

  const dialogBox = css`
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 6px -4px #333;
  `
  const holdBtn = css`
    margin-bottom: 20px;
    text-align: center;
  `

  const styles = {
    img: css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      text-align: center;
      img {
        width: 240px;
        height: 240px;
        object-fit: contain;
      }
    `,
    price: css`
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin: 0;
      }
    `,
    detail: css`
      word-break: break-all;
    `,
    list: css`
      position: fixed;
      right: 0;
      bottom: 0;
      width: 320px;
      display: flex;
      flex-wrap: wrap;
      border-top: 2px solid #aaa;
      background-color: #fff;
      transform: translateY(100%);
      transition: transform 0.3s;
      @media (orientation: portrait) {
        width: 100%;
      }
    `,
    listOpen: css`
      transform: translateY(0);
    `,
    listOpenIcon: css`
      transform: rotate(180deg);
      transition: transform 0.3s;
    `,
    listTab: css`
      position: absolute;
      top: -2px;
      left: 50%;
      width: 160px;
      padding: 2px;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 0 2px #ccc;
      transform: translate(-50%, -100%);
      text-align: center;
      background-color: #fff;
      cursor: pointer;
    `,
    listLink: css`
      width: 80px;
      height: 80px;
      display: block;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.9;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `,
  }

  const confirmClose: JSX.Element = (
    <div css={dialogBox}>
      <p>サイトを閉じますか？</p>
      <Space>
        <Button
          size='small'
          shape='round'
          type='primary'
          ghost
          onClick={() => {
            setShowCloseDialog(false)
            setHolding(true)
          }}
        >
          このまま見る
        </Button>
        <Button
          size='small'
          shape='round'
          danger
          onClick={() => {
            setShowCloseDialog(false)
            setShowSite(false)
          }}
        >
          閉じる
        </Button>
      </Space>
    </div>
  )

  const confirmChange: JSX.Element = (
    <div css={dialogBox}>
      <p>サイトを変更しますか？</p>
      <Space>
        <Button
          size='small'
          shape='round'
          type='primary'
          ghost
          onClick={() => {
            setShowChangeDialog(false)
            setHolding(true)
          }}
        >
          このまま見る
        </Button>
        <Button
          size='small'
          shape='round'
          type='primary'
          onClick={() => {
            setShowChangeDialog(false)
            setLiveSite(holdSite)
          }}
        >
          変更する
        </Button>
      </Space>
    </div>
  )

  const holdingButton = (): JSX.Element => {
    if (!holding) {
      return <></>
    } else if (!holdShow || !holdSite) {
      return (
        <div css={holdBtn}>
          <Button
            type='primary'
            shape='round'
            icon={<DisconnectOutlined />}
            onClick={() => {
              setShowSite(holdShow)
              setLiveSite(holdSite)
              setHolding(false)
            }}
          >
            サイトを閉じる
          </Button>
        </div>
      )
    } else {
      return (
        <div css={holdBtn}>
          <Button
            type='primary'
            shape='round'
            icon={<RedoOutlined />}
            onClick={() => {
              setShowSite(holdShow)
              setLiveSite(holdSite)
              setHolding(false)
            }}
          >
            サイトを更新
          </Button>
        </div>
      )
    }
  }

  const [images, setImages] = useState<Array<string>>([])
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [imageLength, setImageLength] = useState<number>(0)

  const checkImages = (): void => {
    const array = []
    if (liveSite.image1) {
      array.push(liveSite.image1)
    }
    if (liveSite.image2) {
      array.push(liveSite.image2)
    }
    if (liveSite.image3) {
      array.push(liveSite.image3)
    }
    console.log(array)
    setImages(array)
    setImageIndex(0)
    setImageLength(array.length)
    console.log(imageIndex, imageLength)
  }

  useEffect(() => {
    checkImages()
  }, [liveSite])

  return (
    <div css={container}>
      <Helmet>
        <title>{`${tour?.title}｜ライブコマース`}</title>
      </Helmet>

      <div css={zoomWrap}>
        <iframe
          css={showZoom ? iframe : hideIframe}
          src={zoomUrl}
          frameBorder='0'
        />
      </div>

      {showSite && (
        <div css={siteWrap}>
          {showCloseDialog && confirmClose}
          {showChangeDialog && confirmChange}
          {holdingButton()}

          {imageLength > 0 && (
            <div css={styles.img}>
              <Button
                type='text'
                shape='circle'
                icon={<LeftOutlined />}
                onClick={() => setImageIndex(imageIndex - 1)}
                disabled={imageIndex === 0}
              />
              <img src={images[imageIndex]} alt='商品画像' />
              <Button
                type='text'
                shape='circle'
                icon={<RightOutlined />}
                onClick={() => setImageIndex(imageIndex + 1)}
                disabled={imageIndex === imageLength - 1}
              />
            </div>
          )}

          <h2>{liveSite.site_name}</h2>
          <div css={styles.price}>
            <p>￥{liveSite.price}</p>
            <a
              href={liveSite.site_url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button type='primary' shape='round'>
                商品購入ページへ
              </Button>
            </a>
          </div>
          <p css={styles.detail}>{liveSite.detail}</p>
        </div>
      )}

      {!showSite && showList && <div css={siteWrap}></div>}

      {showList && (
        <div css={[styles.list, openList && styles.listOpen]}>
          <div onClick={() => setOpenList(!openList)} css={styles.listTab}>
            <span style={{ marginRight: '5px' }}>商品一覧</span>
            <UpOutlined css={openList && styles.listOpenIcon} />
          </div>
          {sites.map((site) => {
            return (
              <a
                key={site.id}
                href={site.site_url}
                css={styles.listLink}
                title={site.site_name}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={site.image1} alt={site.site_name} />
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Top
