import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageHeader, Button, Form, Input } from 'antd'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const Entry: React.FC = () => {
  const [name, setName] = useState<string>('')
  const navigation = useNavigate()
  const location = useLocation()
  const urlParams = location.search

  const handleSubmit = async () => {
    navigation(`/${urlParams}&name=${name}`)
    window.location.reload()
  }

  const container = css`
    min-height: 100vh;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: -moz-linear-gradient(top right, #fff, #eee);
    background: -webkit-linear-gradient(top right, #fff, #eee);
    background: linear-gradient(to bottom left, #fff, #eee);
    @media screen and (max-width: 600px) {
      padding: 0 10px;
    }
  `
  const wrap = css`
    width: 90%;
    max-width: 640px;
    padding: 60px 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 4px #eee;
  `
  const form = css`
    width: 90%;
    /* max-width: 720px; */
  `

  return (
    <div css={container}>
      <div css={wrap}>
        <PageHeader title='名前を入力' />
        <Form css={form}>
          <Form.Item>
            <Input
              placeholder='Zoomに表示する名前を入力してください'
              onChange={(event) => setName(event.target.value)}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button onClick={handleSubmit} type='primary' disabled={!name}>
              ログイン
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Entry
