import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Form, Input, InputNumber, Space, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const apiUrl = process.env.REACT_APP_API_URL

type Site = {
  id?: number
  tour_id?: number
  site_name?: string
  site_url?: string
  created_at?: string
  updated_at?: string
  price?: number
  image1?: string
  image2?: string
  image3?: string
  detail?: string
}

type Props = {
  site: Site
  getTour: any
  setFlag: any
}

const SiteEdit: React.FC<Props> = ({ site, getTour, setFlag }) => {
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    console.log(site)
  })

  const styles = {
    form: css`
      width: 100%;
    `,
    file: css`
      display: flex;
    `,
    img: css`
      display: flex;
      margin-right: 10px;
      img {
        width: 120px;
        height: 120px;
        border: 1px solid #eee;
        object-fit: contain;
      }
    `,
  }

  const addTour = async () => {
    setLoading(true)
    const formData: any = new FormData()
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        return
      }
      formData.append(key, data[key])
    })
    try {
      const res = await axios.post(`${apiUrl}/sites/${site.id}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          'X-HTTP-Method-Override': 'PUT',
        },
      })
      await getTour()
      setFlag(false)
      console.log(res.data)
      message.success('商品情報を更新しました')
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div css={styles.form}>
      <Form labelCol={{ span: 5 }} initialValues={site} onFinish={addTour}>
        <Form.Item
          label='商品名'
          name='site_name'
          rules={[{ required: true, message: '商品名は必須です' }]}
        >
          <Input
            onChange={(event) =>
              setData({ ...data, site_name: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item label='金額' name='price'>
          <InputNumber
            addonBefore='￥'
            onChange={(event) => setData({ ...data, price: Number(event) })}
          />
        </Form.Item>

        <Form.Item
          label='URL'
          name='site_url'
          rules={[{ required: true, message: 'URLは必須です' }]}
        >
          <Input
            onChange={(event) =>
              setData({ ...data, site_url: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item label='商品画像1'>
          <div css={styles.file}>
            {site.image1 && !data.image1 && !data.deleteImage1 && (
              <div css={styles.img}>
                <img src={site.image1} alt='' />
                <Button
                  shape='circle'
                  size='small'
                  type='text'
                  icon={<CloseOutlined />}
                  onClick={() => setData({ ...data, deleteImage1: true })}
                />
              </div>
            )}
            <input
              type='file'
              accept='image/png, image/jpeg'
              style={{ paddingTop: '2px' }}
              onChange={(event) =>
                setData({
                  ...data,
                  image1: event.target.files && event.target.files[0],
                })
              }
            />
          </div>
        </Form.Item>

        <Form.Item label='商品画像2'>
          <div css={styles.file}>
            {site.image2 && !data.image2 && !data.deleteImage2 && (
              <div css={styles.img}>
                <img src={site.image2} alt='' />
                <Button
                  shape='circle'
                  size='small'
                  type='text'
                  icon={<CloseOutlined />}
                  onClick={() => setData({ ...data, deleteImage2: true })}
                />
              </div>
            )}
            <input
              type='file'
              accept='image/png, image/jpeg'
              style={{ paddingTop: '2px' }}
              onChange={(event) => {
                setData({
                  ...data,
                  image2: event.target.files && event.target.files[0],
                })
              }}
            />
          </div>
        </Form.Item>

        <Form.Item label='商品画像3'>
          <div css={styles.file}>
            {site.image3 && !data.image3 && !data.deleteImage3 && (
              <div css={styles.img}>
                <img src={site.image3} alt='' />
                <Button
                  shape='circle'
                  size='small'
                  type='text'
                  icon={<CloseOutlined />}
                  onClick={() => setData({ ...data, deleteImage3: true })}
                />
              </div>
            )}
            <input
              type='file'
              accept='image/png, image/jpeg'
              style={{ paddingTop: '2px' }}
              onChange={(event) => {
                setData({
                  ...data,
                  image3: event.target.files && event.target.files[0],
                })
              }}
            />
          </div>
        </Form.Item>

        <Form.Item label='商品説明' name='detail'>
          <Input.TextArea
            onChange={(event) =>
              setData({ ...data, detail: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5 }}>
          <Space>
            <Button type='primary' htmlType='submit' loading={loading}>
              更新
            </Button>
            <Button
              onClick={() => {
                setFlag(false)
              }}
            >
              キャンセル
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SiteEdit
