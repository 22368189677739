import React, { useState } from 'react'
import axios from 'axios'
import { PageHeader, Button, Form, Input, Space, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const apiUrl = process.env.REACT_APP_API_URL

type Tour = {
  title?: string
  meeting_number?: string
  meeting_pwd?: string
}

type Props = {
  getTours: any
  setFlag: any
}

const TourCreate: React.FC<Props> = ({ getTours, setFlag }) => {
  const [tour, setTour] = useState<Tour>({})

  const styles = {
    wrapper: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 300;
    `,
    form: css`
      width: 90%;
      max-width: 720px;
      padding: 40px;
      padding-top: 20px;
      border-radius: 20px;
      background-color: #fff;
    `,
  }

  const addTour = async () => {
    try {
      const res = await axios.post(`${apiUrl}/tours`, tour)
      await getTours()
      setFlag(false)
      console.log(res.data)
      message.success('ツアーを作成しました')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.form}>
        <PageHeader
          title='ツアー作成'
          extra={
            <Button
              shape='circle'
              type='text'
              icon={<CloseOutlined />}
              onClick={() => setFlag(false)}
            />
          }
        />
        <Form labelCol={{ span: 7 }} onFinish={addTour}>
          <Form.Item label='ツアー名'>
            <Input
              onChange={(event) =>
                setTour({ ...tour, title: event.target.value })
              }
            />
          </Form.Item>

          <Form.Item label='ミーティングID'>
            <Input
              onChange={(event) =>
                setTour({
                  ...tour,
                  meeting_number: event.target.value.replace(/\s+/g, ''),
                })
              }
            />
          </Form.Item>

          <Form.Item label='ミーティングパスワード'>
            <Input
              onChange={(event) =>
                setTour({ ...tour, meeting_pwd: event.target.value })
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 7 }}>
            <Space>
              <Button
                type='primary'
                htmlType='submit'
                disabled={
                  !(tour.title && tour.meeting_number && tour.meeting_pwd)
                }
              >
                登録
              </Button>
              <Button
                onClick={() => {
                  setFlag(false)
                }}
              >
                キャンセル
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default TourCreate
