import React, { useState } from 'react'
import axios from 'axios'
import { Button, Form, Input, InputNumber, Space, message } from 'antd'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const apiUrl = process.env.REACT_APP_API_URL

type Site = {
  tour_id?: number
  site_name?: string
  site_url?: string
  price?: number
  detail?: string
}

type Props = {
  tourId?: number
  getTour: any
  setFlag: any
}

const SiteCreate: React.FC<Props> = ({ tourId, getTour, setFlag }) => {
  const [data, setData] = useState<any>({ tour_id: tourId })
  const [loading, setLoading] = useState<boolean>(false)

  const styles = {
    form: css`
      width: 100%;
    `,
  }

  const addTour = async () => {
    setLoading(true)
    const formData: any = new FormData()
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        return
      }
      formData.append(key, data[key])
    })
    try {
      const res = await axios.post(`${apiUrl}/sites`, formData)
      await getTour()
      setFlag(false)
      console.log(res.data)
      message.success('サイトを追加しました')
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div css={styles.form}>
      <Form labelCol={{ span: 5 }} onFinish={addTour}>
        <Form.Item
          label='商品名'
          name='site_name'
          rules={[{ required: true, message: '商品名は必須です' }]}
        >
          <Input
            onChange={(event) =>
              setData({ ...data, site_name: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item label='金額'>
          <InputNumber
            addonBefore='￥'
            onChange={(event) => setData({ ...data, price: Number(event) })}
          />
        </Form.Item>

        <Form.Item
          label='URL'
          name='site_url'
          rules={[{ required: true, message: 'URLは必須です' }]}
        >
          <Input
            onChange={(event) =>
              setData({ ...data, site_url: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item label='商品画像1'>
          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={(event) =>
              setData({
                ...data,
                image1: event.target.files && event.target.files[0],
              })
            }
          />
        </Form.Item>

        <Form.Item label='商品画像2'>
          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={(event) => {
              setData({
                ...data,
                image2: event.target.files && event.target.files[0],
              })
            }}
          />
        </Form.Item>

        <Form.Item label='商品画像3'>
          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={(event) => {
              setData({
                ...data,
                image3: event.target.files && event.target.files[0],
              })
            }}
          />
        </Form.Item>

        <Form.Item label='商品説明'>
          <Input.TextArea
            onChange={(event) =>
              setData({ ...data, detail: event.target.value })
            }
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5 }}>
          <Space>
            <Button type='primary' htmlType='submit' loading={loading}>
              追加
            </Button>
            <Button
              onClick={() => {
                setFlag(false)
              }}
            >
              キャンセル
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SiteCreate
