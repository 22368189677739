import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import {
  PageHeader,
  Divider,
  Button,
  Table,
  Card,
  Space,
  Switch,
  message,
  Modal,
} from 'antd'
const { Column } = Table
const { Meta } = Card
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import TourEdit from './TourEdit'
import SiteCreate from './SiteCreate'
import SiteEdit from './SiteEdit'

const siteUrl = process.env.REACT_APP_SITE_URL
const apiUrl = process.env.REACT_APP_API_URL

type Tour = {
  id?: number
  title?: string
  meeting_number?: string
  meeting_pwd?: string
  created_at?: string
  updated_at?: string
  show_list?: boolean
  sites?: Site[]
  live?: Live
}

type Site = {
  id?: number
  tour_id?: number
  site_name?: string
  site_url?: string
  created_at?: string
  updated_at?: string
  price?: number
  image1?: string
  image2?: string
  image3?: string
  detail?: string
  live?: Live
}

type Live = {
  id?: number
  tour_id?: number
  site_id?: number
  display?: boolean
  created_at?: string
  updated_at?: string
  site?: Site
}

const Tour: React.FC = () => {
  const [tour, setTour] = useState<Tour>({})
  const [site, setSite] = useState<Site>({})
  const [liveId, setLiveId] = useState<number>()
  const [showLive, setShowLive] = useState<boolean>(true)
  const [showTourEdit, setShowTourEdit] = useState<boolean>(false)
  const [showSiteCreate, setShowSiteCreate] = useState<boolean>(false)
  const [showSiteEdit, setShowSiteEdit] = useState<boolean>(false)
  const params = useParams()
  const navigation = useNavigate()

  useEffect(() => {
    getTour()
  }, [])

  const getTour = async () => {
    try {
      const res = await axios.get(`${apiUrl}/tours/${params.id}`)
      console.log(res.data)
      setTour(res.data)
      setLiveId(res.data.live ? res.data.live.id : null)
      setShowLive(res.data.live?.display ? true : false)
    } catch (error) {
      console.log(error)
    }
  }

  const changeShowList = async (checked: boolean) => {
    console.log(checked)
    try {
      const res = await axios.put(`${apiUrl}/tours/${tour.id}`, {
        show_list: checked,
      })
      console.log(res.data)
      setTour({ ...tour, show_list: checked })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteTour = async () => {
    const check: boolean = confirm('削除しますか？')
    if (!check) {
      return
    }
    try {
      await axios.delete(`${apiUrl}/tours/${tour.id}`)
      message.success('ツアーを削除しました')
      navigation('/admin')
    } catch (error) {
      console.log(error)
    }
  }

  const deleteSite = async (id: number) => {
    const check: boolean = confirm('削除しますか？')
    if (!check) {
      return
    }
    try {
      await axios.delete(`${apiUrl}/sites/${id}`)
      message.success('サイトを削除しました')
      getTour()
    } catch (error) {
      console.log(error)
    }
  }

  const changeLive = (siteId: number, checked: boolean): void => {
    if (checked) {
      selectLive(siteId)
    } else {
      unsetLive()
    }
  }

  const selectLive = async (siteId: number) => {
    try {
      if (liveId) {
        await axios.put(`${apiUrl}/lives/${liveId}`, {
          site_id: siteId,
          display: true,
        })
      } else {
        await axios.post(`${apiUrl}/lives`, {
          tour_id: tour.id,
          site_id: siteId,
        })
      }
      getTour()
    } catch (error) {
      console.log(error)
    }
  }

  const unsetLive = async () => {
    try {
      await axios.put(`${apiUrl}/lives/${liveId}`, {
        display: false,
      })
      getTour()
    } catch (error) {
      console.log(error)
    }
  }

  const container = css`
    min-height: 100vh;
    padding: 30px 50px;
    background-color: #fdfdfd;
  `
  const addButton = css`
    margin-top: 10px;
    text-align: center;
  `
  const live = {
    wrap: css`
      display: flex;
      padding: 20px;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      background-color: #fff;
    `,
    left: css`
      display: flex;
      align-items: center;
      width: 160px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `,
    right: css`
      flex: 1;
      margin-left: 40px;
    `,
  }

  const image = css`
    width: 120px;
    height: 120px;
    object-fit: contain;
  `

  const displayImage = (site: any): string => {
    if (site?.image1) {
      return site.image1
    } else if (site?.image2) {
      return site.image2
    } else if (site?.image3) {
      return site.image3
    } else {
      return ''
    }
  }

  return (
    <div css={container}>
      <PageHeader onBack={() => window.history.back()} title='ツアー詳細' />
      <Card
        title={tour.title}
        extra={
          <Space>
            <Button type='primary' onClick={() => setShowTourEdit(true)}>
              編集
            </Button>
            <Button danger onClick={deleteTour}>
              削除
            </Button>
          </Space>
        }
      >
        <p>ミーティングID：{tour.meeting_number}</p>
        <p>ミーティングパスワード：{tour.meeting_pwd}</p>
        <p>
          共有用リンク：
          <a
            href={`${siteUrl}?mn=${tour.meeting_number}&pwd=${tour.meeting_pwd}`}
            target='_blank'
            rel='noopener noreferrer'
          >{`${siteUrl}?mn=${tour.meeting_number}&pwd=${tour.meeting_pwd}`}</a>
        </p>
        <p>
          商品一覧の表示：
          <Switch
            checked={tour.show_list}
            onChange={(checked) => changeShowList(checked)}
          />
        </p>
      </Card>

      {showTourEdit && (
        <TourEdit
          tourData={tour}
          getTours={getTour}
          setFlag={setShowTourEdit}
        />
      )}

      <Divider style={{ marginTop: '30px', fontWeight: 'bold' }}>
        表示中の商品
      </Divider>

      {liveId && showLive ? (
        <div css={live.wrap}>
          <div css={live.left}>
            {displayImage(tour?.live?.site) && (
              <img src={displayImage(tour?.live?.site)} />
            )}
          </div>
          <div css={live.right}>
            <h3>{tour?.live?.site?.site_name}</h3>
            <p>金額：￥{tour?.live?.site?.price}</p>
            <p>
              URL：
              <a href={tour?.live?.site?.site_url}>
                {tour?.live?.site?.site_url}
              </a>
            </p>
            <p>{tour?.live?.site?.detail}</p>
          </div>
        </div>
      ) : (
        <Card>
          <Meta title='登録されていません' />
        </Card>
      )}

      <Divider style={{ marginTop: '30px', fontWeight: 'bold' }}>
        商品一覧
      </Divider>

      <Table dataSource={tour.sites} size='small' pagination={false}>
        <Column
          title='表示'
          key='live'
          width={60}
          render={(site) => {
            return (
              <Switch
                checked={showLive && site.live}
                onChange={(checked) => changeLive(site.id, checked)}
              />
            )
          }}
        />
        <Column title='商品名' dataIndex='site_name' key='site_name' />
        <Column
          title='金額'
          key='price'
          dataIndex='price'
          width={60}
          render={(price) => {
            return <span>￥{price}</span>
          }}
        />
        <Column
          title='URL'
          dataIndex='site_url'
          key='site_url'
          render={(site_url) => {
            return (
              <a href={site_url} target='_blank' rel='noopener noreferrer'>
                {site_url}
              </a>
            )
          }}
        />
        <Column
          title='画像'
          key='image1'
          width={120}
          render={(value) => {
            return (
              displayImage(value) && (
                <img css={image} src={displayImage(value)} width={120} />
              )
            )
          }}
        />
        <Column
          title=''
          key='id'
          width={120}
          render={(value) => {
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => {
                    setSite(value)
                    setShowSiteEdit(true)
                  }}
                >
                  編集
                </Button>
                <Button danger onClick={() => deleteSite(value.id)}>
                  削除
                </Button>
              </Space>
            )
          }}
        />
      </Table>

      <div css={addButton}>
        <Button type='primary' onClick={() => setShowSiteCreate(true)}>
          商品を追加
        </Button>
      </div>

      {showSiteCreate && (
        <Modal
          title='商品追加'
          visible
          onCancel={() => setShowSiteCreate(false)}
          footer=''
          width='90%'
          style={{ top: '60px', maxWidth: '720px' }}
        >
          <SiteCreate
            tourId={tour.id}
            getTour={getTour}
            setFlag={setShowSiteCreate}
          />
        </Modal>
      )}

      {showSiteEdit && (
        <Modal
          title='商品編集'
          visible
          onCancel={() => setShowSiteEdit(false)}
          footer=''
          width='90%'
          style={{ top: '30px', maxWidth: '720px' }}
        >
          <SiteEdit site={site} getTour={getTour} setFlag={setShowSiteEdit} />
        </Modal>
      )}
    </div>
  )
}

export default Tour
