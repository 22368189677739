import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Top from './components/Top'
import Admin from './components/Admin'
import Tour from './components/Tour'
import './App.css'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Top />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/admin/:id' element={<Tour />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
