import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { PageHeader, Button, Table, Space, message } from 'antd'
const { Column } = Table
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import TourCreate from './TourCreate'
import TourEdit from './TourEdit'

const apiUrl = process.env.REACT_APP_API_URL

type Tour = {
  id?: number
  key?: number
  title?: string
  meeting_number?: string
  meeting_pwd?: string
  created_at?: string
  updated_at?: string
}

const Admin: React.FC = () => {
  const [tours, setTours] = useState<Tour[]>([])
  const [tour, setTour] = useState<Tour>({})
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)

  useEffect(() => {
    getTours()
  }, [])

  const getTours = async () => {
    try {
      const res = await axios.get(`${apiUrl}/tours`)
      res.data.forEach((el: any) => {
        el.key = el.id
      })
      setTours(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteTour = async (id: number) => {
    const check: boolean = confirm('削除しますか？')
    if (!check) {
      return
    }
    try {
      await axios.delete(`${apiUrl}/tours/${id}`)
      message.success('ツアーを削除しました')
      getTours()
    } catch (error) {
      console.log(error)
    }
  }

  const container = css`
    min-height: 100vh;
    padding: 30px 50px;
    background-color: #fdfdfd;
  `

  return (
    <div css={container}>
      <PageHeader
        title='ツアー一覧'
        extra={
          <Button type='primary' onClick={() => setShowCreate(true)}>
            作成
          </Button>
        }
      />
      <Table
        dataSource={tours}
        pagination={{ pageSize: 20, position: ['bottomCenter'] }}
      >
        <Column
          title='タイトル'
          key='title'
          render={(value) => {
            return <Link to={`/admin/${value.id}`}>{value.title}</Link>
          }}
        />
        <Column
          title='ミーティングID'
          dataIndex='meeting_number'
          key='meeting_number'
        />
        <Column title='パスワード' dataIndex='meeting_pwd' key='meeting_pwd' />

        <Column
          title=''
          key='id'
          width={120}
          render={(value) => {
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => {
                    setShowEdit(true)
                    setTour(value)
                  }}
                >
                  編集
                </Button>
                <Button danger onClick={() => deleteTour(value.id)}>
                  削除
                </Button>
              </Space>
            )
          }}
        />
      </Table>

      {showCreate && <TourCreate getTours={getTours} setFlag={setShowCreate} />}
      {showEdit && (
        <TourEdit tourData={tour} getTours={getTours} setFlag={setShowEdit} />
      )}
    </div>
  )
}

export default Admin
